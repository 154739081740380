var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAccess === true)?_c('button',{class:'flex	p-0 w-full justify-start items-center text-base mb-3 no-underline font-Rubik hover:no-underline ' +
      (_vm.isActive
        ? 'text-[#FFD154] font-bold hover:text-[#FFD154]'
        : 'text-white font-normal hover:text-white '),on:{"click":_vm.props.onclick}},[_c('img',{staticClass:"ml-8 mr-3 w-8",attrs:{"src":require('@/assets/MenuLateral/' +
          _vm.props.icon +
          (_vm.isActive ? 'Marked' : '') +
          '.svg')}}),(_vm.open)?_c('span',[_vm._v(" "+_vm._s(_vm.props.title)+" ")]):_vm._e(),(_vm.isActive)?_c('span',{staticClass:"block ml-auto bg-[#FFD154] h-12 w-2"}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }