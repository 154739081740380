var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shrink-0 mb-[-10rem] z-50 md:sticky top-0 flex flex-col h-screen bg-[#0A4266]",class:{
    'w-[5.625rem] max-md:hidden':!this.open,
    'w-[18em] max-md:fixed':this.open
  }},[_c('span',{staticClass:"absolute bg-[#FFD154] w-6 h-12 mt-16 hover:cursor-pointer",class:{
      ' rotate-180 left-[18rem]':this.open,
      ' left-[5.625rem]':!this.open
    },on:{"click":this.toggleMenu}},[_c('img',{staticClass:"pt-3 m-auto",attrs:{"src":require("@/assets/MenuLateral/toggle_open_icon.svg"),"alt":""}})]),(!this.open)?_c('div',{staticClass:"w-full h-56 bg-[url('assets/MenuLateral/logo_puma_closed_menu.svg')] bg-no-repeat bg-center bg-[length:3rem]"}):_vm._e(),(this.open)?_c('div',{staticClass:"w-full h-52 bg-[url('assets/MenuLateral/logo_puma.svg')] bg-no-repeat bg-center bg-[length:13.75rem]"}):_vm._e(),_c('div',{staticClass:"items-start"},[_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.myProjects}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.assessProposal}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.trackProjects}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.subjects}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.turmas}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.submitProposal}}),_c('ItemMenuLateral',{attrs:{"open":this.open,"props":this.menuItems.competences}})],1),_c('button',{staticClass:"flex items-center justify-start mt-auto mb-8 ml-8 text-base text-white font-rubik",on:{"click":() => this.$router.push({ name: 'Home Page',}).catch(() => {})}},[_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/MenuLateral/HomePageIcon.svg'),"alt":"homepageicon"}}),_vm._v(" "+_vm._s(this.open ? 'Homepage' : '')+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }