<template>
  <div v-if="popUpActive">
    <div
      class="fixed w-screen h-screen bg-[#878787] mix-blend-multiply top-0 right-0 z-50"
    />
    <div
      class="fixed left-[50%] w-[56rem] rounded-2xl bg-[#E4F4FA] z-50 -translate-x-1/2 max-w-[90%] md:top-6 top-16 md:pb-0 pb-1"
    >
      <div
        class="flex justify-between max-md:mb-4 md:mb-7 max-md:ml-3 md:ml-8 max-md:mt-4 md:mt-6"
      >
        <h2 class="font-bold font-[Orkney] max-md:text-3xl md:text-4xl">
          Contato
        </h2>
        <button
          class="w-fit h-fit p-2 max-md:mr-1 md:mr-9"
          @click="this.closePopUp"
        >
          <img class="w-4 h-4" src="../../assets/contatoPopUp/x.png" alt="" />
        </button>
      </div>
      <div>
        <div
          class="flex max-md:ml-3 md:ml-14 max-md:text-lg md:text-xl mb-4 md:leading-6"
          v-for="(information, index) in informations"
          :key="index"
        >
          <img
            class="max-md:mr-2 md:h-7 max-md:h-6 md:mr-6"
            :src="information.logo"
            :alt="information.label"
          />
          <div class="max-md:font-normal font-[rubik] w-[35.625rem]">
            <p>
              {{ information.label }}
            </p>
            <p class="font-bold max-md:text-sm">
              {{ information.details }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ContactPopUpEventBus } from './eventbuss';

const informations = ref([
  {
    logo: require('../../assets/contatoPopUp/Vector.png'),
    label: 'Endereço:',
    details:
      'Faculdade de Tecnologia (FT), Bloco E, Campus Universitário Darcy Ribeiro, Asa Norte, Brasília-DF',
  },
  {
    logo: require('../../assets/contatoPopUp/Vector-1.png'),
    label: 'CEP:',
    details: '70.910-900',
  },
  {
    logo: require('../../assets/contatoPopUp/Vector-2.png'),
    label: 'Telefone da secretaria:',
    details: '(61) 3107-5678',
  },
  {
    logo: require('../../assets/contatoPopUp/Vector-3.png'),
    label: 'E-mail para contato:',
    details: 'pumaprojeto@gmail.com',
  },
]);

const popUpActive = ref(false);
const closePopUp = () => {
  popUpActive.value = false;
};
const openPopUp = () => {
  popUpActive.value = true;
};

export default {
  name: 'ContactPopUp',
  mounted() {
    ContactPopUpEventBus.$on('open-contact-popup', openPopUp);
  },
  data() {
    return { popUpActive, informations, closePopUp, openPopUp };
  },
};
</script>
