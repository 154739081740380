export default {
  props: {
    text: String,
    iconFilename: String,
    buttonAction: Function,
    textColor: String,
    backgroundColor: String,
    borderColor: String,
  },

  methods: {
    handleClick() {
      this.$emit('button-clicked', true);
    },
  },

  computed: {
    style() {
      const borderString = `1px solid ${this.borderColor}`;

      return `
        background-color: ${this.backgroundColor};
        color: ${this.textColor};
        border: ${borderString}`;
    },
  },
};
