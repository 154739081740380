import USER_CONST from '../../../utils/enums/users.enum';

import ItemMenuLateral from './item-menu-lateral/item-menu-lateral.vue';

export default {
  name: 'MenuLateral',
  components: { ItemMenuLateral },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu', !this.open);
    },
  },
  data() {
    return {
      // menuOpen,
      // toggleMenu,
      user: this.$store.getters.user,
      menuItems: {
        myProjects: {
          basePath: '/meus-projetos',
          title: 'Meus Projetos',
          // eslint-disable-next-line
          icon: 'MeusProjetos',
          // @ts-ignore
          show: () => true,
          onclick: () => {
            this.$router.push({ path: '/meus-projetos' }).catch(() => {});
          },
        },
        assessProposal: {
          basePath: '/avaliacao-de-projetos',
          title: 'Avaliar propostas',
          // eslint-disable-next-line
          icon: 'AssesProposals',
          // @ts-ignore
          show: () =>
            [USER_CONST.TYPES.PROFESSOR.KEY].some(
              type => type === this.user.type,
            ) || this.user.isAdmin,
          onclick: () => {
            this.$router
              .push({ path: '/avaliacao-de-projetos' })
              .catch(() => {});
          },
        },
        submitProposal: {
          basePath: '/submissao',
          title: 'Submeter propostas',
          // eslint-disable-next-line
          icon: 'SubmitProposals',
          // @ts-ignore
          show: () => true,
          onclick: () => {
            this.$router.push({ path: '/submissao' }).catch(() => {});
          },
        },
        turmas: {
          basePath: '/turmas',
          title: 'Turmas',
          // eslint-disable-next-line
          icon: 'Turmas',
          // @ts-ignore
          show: () => true,
          onclick: () => {
            this.$router.push({ path: '/turmas' }).catch(() => {});
          },
        },
        subjects: {
          basePath: '/disciplinas',
          title: 'Disciplinas',
          // eslint-disable-next-line
          icon: 'Subjects',
          // @ts-ignore
          show: () =>
            [USER_CONST.TYPES.PROFESSOR.KEY].some(
              type => type === this.user.type,
            ) ||
            this.user.isAdmin ||
            this.user.type === USER_CONST.TYPES.STUDENT.KEY,
          onclick: () => {
            this.$router.push({ path: '/disciplinas' }).catch(() => {});
          },
        },
        trackProjects: {
          basePath: '/acompanhar-projetos',
          title: 'Acompanhar projetos',
          // eslint-disable-next-line
          icon: 'TrackProjects',
          // @ts-ignore
          show: () =>
            [USER_CONST.TYPES.PROFESSOR.KEY].some(
              type => type === this.user.type,
            ) || this.user.isAdmin,
          onclick: () => {
            this.$router.push({ path: '/acompanhar-projetos' }).catch(() => {});
          },
        },
        competences: {
          basePath: '/competencias',
          title: 'Competencias',
          // eslint-disable-next-line
          icon: 'Competence',
          // @ts-ignore
          show: () =>
            [USER_CONST.TYPES.PROFESSOR.KEY].some(
              type => type === this.user.type,
            ) || this.user.isAdmin,
          onclick: () => {
            this.$router.push({ path: '/competencias' }).catch(() => {});
          },
        },
        logout: {
          title: 'Sair',
          // eslint-disable-next-line
          iconUrl: require('@/assets/menu-3.svg'),
          // @ts-ignore
          show: () => true,
          onclick: () => {
            this.$store.commit('RESET_USER_STATE');
            this.$router.push({ path: '/login' }).catch(() => {});
          },
        },
      },
    };
  },
};
