import UserMenu from '../../user-menu/UserMenu.vue';

export default {
  name: 'TopBar',
  components: { UserMenu },
  data() {
    return {
      pageName: this.$route.meta.name || '',
    };
  },
  methods: {
    openMenu() {
      this.$emit('toggle-menu', true);
    },
  },
};
