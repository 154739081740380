import ButtonComponent from '../../ButtonComponent/ButtonComponent.vue';
import store from '../../../store';
import UserMenu from '../../user-menu/UserMenu.vue';

export default {
  props: {
    pagina: String,
  },

  components: {
    ButtonComponent,
    UserMenu,
  },

  data() {
    return {
      isLogged: store.getters.isAuthenticated,
      dispMenu: false,
      listaOpcoesHeader: [
        {
          nome: 'Sobre a PUMA',
          link: '/home/Sobre',
        },
        {
          nome: 'Editais',
          link: '/home/editais',
        },
        {
          nome: 'Conheça o PBL',
          link: '/home/conheca-pbl',
        },
        {
          nome: 'Nossos projetos',
          link: '/home/nossos-projetos',
        },
      ],
    };
  },

  methods: {
    getDispMenu() {
      return this.dispMenu;
    },

    setDispMenu(value) {
      this.dispMenu = value;
    },

    goToLogin() {
      window.location.href = '/login';
    },
  },
};
