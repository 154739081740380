export default {
  name: 'ItemMenuLateral',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    props: { type: Object },
    open: { type: Boolean, default: true },
  },
  data() {
    return {
      isActive: this.$route.path?.includes(this.props.basePath),
      hasAccess: this.props.show(),
    };
  },
};
