var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"flex items-center justify-between w-full h-20 p-3 bg-darkBlue-900"},[_c('button',{staticClass:"divMobile",on:{"click":function($event){return _vm.setDispMenu(!_vm.dispMenu)}}},[_c('img',{staticClass:"mobileMenu",attrs:{"src":require("../../../assets/headerMobileMenu.png"),"alt":"Menu"}})]),_c('div',{staticClass:"divLogo",class:{'max-xl:hidden': _vm.getDispMenu(),},attrs:{"onclick":"window.location='/'"}},[_c('img',{attrs:{"src":require("../../../assets/logo-puma-header.svg")}})]),_c('div',{staticClass:"xl:flex xl:flex-row justify-between",class:{ 
      'max-xl:hidden': !_vm.getDispMenu(),
      'max-xl:z-50 max-xl:fixed max-xl:left-0 max-xl:top-0 max-xl:w-[18em] max-xl:h-screen max-xl:bg-[#0A4266] max-xl:drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]': _vm.getDispMenu() 
    }},[_c('span',{staticClass:"z-[55] bg-[#FFD154] w-6 h-12 mt-16 hover:cursor-pointer",class:{
      ' max-xl:absolute xl:hidden rotate-180 left-[18rem]':_vm.getDispMenu(),
      ' hidden':!_vm.getDispMenu()
    },on:{"click":function($event){return _vm.setDispMenu(!_vm.dispMenu)}}},[_c('img',{staticClass:"pt-3 m-auto",attrs:{"src":require("@/assets/MenuLateral/toggle_open_icon.svg"),"alt":""}})]),_vm._m(0),_vm._l((this.listaOpcoesHeader),function(item){return _c('div',{staticClass:"opcaoHeader",class:{
          'max-xl:flex max-xl:flex-grow max-xl:pl-8 py-4 ':_vm.getDispMenu(),
        }},[_c('a',{class:{
          'max-xl:flex max-xl:flex-grow':_vm.getDispMenu(),
        },attrs:{"href":item.link}},[_c('span',{staticClass:"opcoesHeaderSpan text-sm md:text-lg md:font-semibold font-medium text-white"},[_vm._v(_vm._s(item.nome))])])])}),_vm._t("default")],2),(!_vm.isLogged)?_c('div',{staticClass:"authentication",class:{ 'none': !_vm.getDispMenu(), 'block': _vm.getDispMenu() }}):_vm._e(),(_vm.isLogged)?_c('UserMenu'):_c('button',{staticClass:"md:text-[#FFD154] leading-none md:font-semibold max-md:text-sm md:text-lg md:border-solid md:border-[1px] md:border-[#FFD154] md:px-[2.625rem] max-md:text-white max-md:block",on:{"click":_vm.goToLogin}},[_c('div',{staticClass:"md:hidden bg-[url('assets/loginmobilelogo.png')] bg-no-repeat bg-center h-[1.563rem] w-full block m-0 p-0"}),_vm._v(" Login "),_c('i',{staticClass:"max-md:hidden bg-[url('assets/loginHeader.png')] bg-no-repeat bg-center h-[1.563rem] w-[1.563rem] block"})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xl:hidden ml-8 my-12",attrs:{"onclick":"window.location='/'"}},[_c('img',{staticClass:"h-14",attrs:{"src":require("../../../assets/logo-puma-header.svg")}})])
}]

export { render, staticRenderFns }