<template>
  <div id="app">
    <link
      href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,900"
      rel="stylesheet"
    />
    <div class="flex w-full">
      <MenuLateral
        :open="this.menuOpen"
        @toggle-menu="this.updateMenuOpen"
        :key="this.$route.path"
        v-if="this.isAuthenticated && this.needsAuth"
      />
      <div class="max-w-full grow">
        <!-- <div> -->
        <TopBar
          :key="this.$route.path"
          @toggle-menu="this.updateMenuOpen"
          v-if="this.isAuthenticated && this.needsAuth"
        />
        <AreaExternaHeader v-else>
          <button
            class="opcaoHeader max-xl:flex max-xl:flex-grow max-xl:pl-8 py-4 pr-0 mr-0"
            @click="this.openContactPopUp"
          >
            <span
              class="opcoesHeaderSpan text-sm md:text-lg md:font-semibold font-medium text-white"
            >
              Contatos</span
            >
          </button>
        </AreaExternaHeader>
        <ContatoPopUp />

        <router-view />
      </div>
    </div>
    <BoasVindasPopUp
      :key="this.BoasVindasPopUpKey"
      v-if="this.isAuthenticated"
    />
    <ConfirmModal />
    <LoadingModal />
  </div>
</template>

<script>
import ConfirmModal from './components/ConfirmModal/ConfirmModal.vue';
import LoadingModal from './components/LoadingModal/LoadingModal.vue';
import BoasVindasPopUp from './components/boasVindasPopUp/boasVindasPopUp.vue';
import MenuLateral from './components/shared/menu-lateral/MenuLateral.vue';
import TopBar from './components/shared/top-bar/TopBar.vue';
import AreaExternaHeader from './components/AreaExterna/AreaExternaHeader/AreaExternaHeader.vue';
import ContatoPopUp from './components/contatoPopUp/contatoPopUp.vue';
import { ContactPopUpEventBus } from './components/contatoPopUp/eventbuss';

// const openContactPopUp = () => {
//   ContactPopUpEventBus.$emit('open-contact-popup');
// };
export default {
  components: {
    ConfirmModal,
    LoadingModal,
    BoasVindasPopUp,
    MenuLateral,
    TopBar,
    AreaExternaHeader,
    ContatoPopUp,
  },
  methods: {
    openContactPopUp() {
      ContactPopUpEventBus.$emit('open-contact-popup');
    },
    forceReloadBoasVindasPopUp() {
      this.BoasVindasPopUpKey += 1;
    },
    updateSessionStatus() {
      this.isAuthenticated = this.$store.getters.isAuthenticated;
      this.BoasVindasPopUpKey = this.$store.getters.isAuthenticated;
    },
    updateMenuOpen(novoValor) {
      localStorage.setItem('menuOpen', novoValor);
      this.menuOpen = novoValor;
    },
    updateNeedsAuth() {
      this.needsAuth = this.$route.meta.requiresAuth === true;
    },
  },
  data() {
    return {
      menuOpen: false,
      needsAuth: false,
      isAuthenticated: false,
      BoasVindasPopUpKey: 0,
    };
  },
  created() {
    this.updateSessionStatus();
    this.updateNeedsAuth();
    const aux = localStorage.getItem('menuOpen');
    this.menuOpen = aux ? aux === 'true' : true;
  },
  updated() {
    this.updateSessionStatus();
    this.updateNeedsAuth();
  },
  mounted() {
    document.title = 'PUMA';
  },
};
</script>

<style>
/* GLOBAL CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Orkney';
  src: url('./assets/fonts/Orkney-Bold.otf');
}
</style>
