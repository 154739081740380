export default {
  name: 'UserMenu',
  data() {
    return {
      loginIsExpanded: false,
      user: this.$store.getters.user,
      needsAuth: this.$route.meta.requiresAuth === true,
    };
  },
  methods: {
    toggleExpansion() {
      this.loginIsExpanded = !this.loginIsExpanded;
      console.log(this.$store.getters.user);
    },
    goToPUMA() {
      window.location.href = '/meus-projetos';
    },
    seeProfile() {
      window.location.href = '/perfil';
    },
    logout() {
      this.$store.commit('RESET_USER_STATE');
      this.$router.push({ path: '/login' }).catch(() => {});
    },
  },
};
