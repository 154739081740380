import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import { env } from '../config/environment';

Vue.use(VueRouter);

const routes = [
  {
    path: '/case',
    name: 'Case Page',
    component: () => import('../views/caseProjeto/caseProjeto.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'Home Page',
    component: () => import('../views/externaHomePage/externaHomePage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/disciplinas',
    name: 'Home Disciplinas',
    component: () =>
      import('../views/externaDisciplinas/externaDisciplinas.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/sobre',
    name: 'Home Sobre',
    component: () => import('../views/externaSobre/externaSobre.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/conheca-pbl',
    name: 'Home ConhecaPBL',
    component: () =>
      import('../views/externaConhecaOPBL/externaConhecaOPBL.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/nossos-projetos',
    name: 'Home NossosProjetos',
    component: () =>
      import('../views/externaNossosProjetos/externaNossosProjetos.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/processo-submissao',
    name: 'Home ProcessoSubmissao',
    component: () =>
      import('../views/externaProcessoSubmissao/externaProcessoSubmissao.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../components/usuario/Autenticacao/Login/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: () =>
      import('../components/usuario/Autenticacao/Autenticacao.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/recuperacao-senha',
    name: 'Recuperação Senha',
    component: () =>
      import('../components/usuario/recovery-password/RecoveryPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/atualizar-senha',
    name: 'Nova Senha',
    component: () =>
      import('../components/usuario/new-password/NewPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home/editais',
    name: 'Edital',
    component: () => import('../components/edital/edital.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/projetos-plataforma',
    name: 'Consulta de Projetos',
    component: () =>
      import('../components/projeto/consulta-projeto/ConsultaProjeto.vue'),
    meta: {
      name: 'Projetos',
      requiresAuth: true,
    },
  },
  {
    path: '/projetos-plataforma/visualizar/:id',
    name: 'Visualizar Projeto',
    component: () =>
      import('../components/projeto/ViewProject/ViewProject.vue'),
    meta: {
      name: 'Projetos',
      requiresAuth: true,
    },
  },
  {
    path: '/projetos-disciplina',
    name: 'Projetos por Disciplina',
    component: () =>
      import('../components/projeto/consulta-projeto/ConsultaProjeto.vue'),
    meta: {
      name: 'Projetos',
      requiresAuth: true,
    },
  },
  {
    path: '/projetos-disciplina/visualizar/:id',
    name: 'Consulta por Disciplina Visualizar',
    component: () =>
      import('../components/projeto/ViewProject/ViewProject.vue'),
    meta: {
      name: 'Projetos',
      requiresAuth: true,
    },
  },
  {
    path: '/acompanhar-projetos',
    name: 'Acompanhar Projetos',
    component: () =>
      import('../components/projeto/acompanharProjeto/acompanharProjeto.vue'),
    meta: {
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/disciplinas',
    name: 'Disciplinas',
    component: () =>
      import(
        '../components/disciplina/consulta-disciplina/ConsultaDisciplina.vue'
      ),
    meta: {
      name: 'Disciplinas',
      requiresAuth: true,
    },
  },
  {
    path: '/disciplinas/cadastrar',
    name: 'Cadastro de Disciplina',
    component: () =>
      import(
        '../components/disciplina/cadastro-disciplina/CadastroDisciplina.vue'
      ),
    meta: {
      name: 'Disciplina',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/disciplinas/editar/:id',
    name: 'Edição de Disciplina',
    component: () =>
      import(
        '../components/disciplina/cadastro-disciplina/CadastroDisciplina.vue'
      ),
    meta: {
      name: 'Disciplina',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/disciplinas/atribuir-projetos',
    name: 'Atribuição de Projetos',
    component: () =>
      import(
        '../components/disciplina/AtribuirProjetosTurmas/AtribuirProjetosTurmas.vue'
      ),
    meta: {
      name: 'Disciplina',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/disciplinas/atribuir-projetos/detalhe/:id',
    name: 'Detalhe da Atribuição de Projetos',
    component: () =>
      import(
        '../components/disciplina/AtribuirProjetosTurmas/DetalheAtribuirProjetosTurmas/DetalheAtribuirProjetosTurmas.vue'
      ),
    meta: {
      name: 'Disciplina',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/disciplinas/visualizar/:id',
    name: 'Visualização de Disciplina',
    component: () =>
      import(
        '../components/disciplina/visualizar-disciplina/VisualizarDisciplina.vue'
      ),
    meta: {
      name: 'Disciplina',
      requiresAuth: true,
      props: true,
    },
  },
  {
    path: '/tipoUsuario',
    name: 'Perfis de usuário',
    component: () =>
      import(
        '../components/tipoUsuario/consulta-tipoUsuario/ConsultaTipoUsuario.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tipoUsuario/cadastrar',
    name: 'Cadastro de Usuário',
    component: () =>
      import(
        '../components/tipoUsuario/cadastro-tipoUsuario/CadastroTipoUsuario.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tipoUsuario/editar/:id',
    name: 'Edição de Usuário',
    component: () =>
      import(
        '../components/tipoUsuario/cadastro-tipoUsuario/CadastroTipoUsuario.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tipoUsuario/visualizar/:id',
    name: 'Visualização de Usuário',
    component: () =>
      import(
        '../components/tipoUsuario/cadastro-tipoUsuario/CadastroTipoUsuario.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/turma/:classid',
  //   name: 'Edição de Turma',
  //   component: () => import('../components/turma/edicao-turma/EdicaoTurma.vue'),
  //   meta: {
  //     name: 'Turmas',
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/turmas',
  //   name: 'Visualização de Turmas',
  //   component: () =>
  //     import('../components/turma/visualizacao-turma/VisualizacaoTurma.vue'),
  //   meta: {
  //     name: 'Turmas',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/Submissao',
    name: 'Submissao',
    component: () =>
      import('../components/SubmissaoProjeto/SubmissaoProjeto.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/avaliacao-de-projetos',
    name: 'AvaliacaoDeProjetos',
    component: () =>
      import('../components/AvaliacaoDeProjetos/AvaliacaoDeProjetos.vue'),
    meta: {
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/avaliacao-de-projetos/detalhe/:id',
    name: 'DetalheAvaliacao',
    component: () =>
      import(
        '../components/AvaliacaoDeProjetos/Detalhe/DetalheAvaliacaoDeProjetos.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/acompanhar-projeto/detalhe/:id',
    name: 'DetalheAcompanhamento',
    component: () =>
      import(
        '../components/projeto/acompanharProjeto/Detalhe/DetalheAcompanharProjeto.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import('../components/Perfil/Perfil.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/meus-projetos',
    name: 'Meus Projetos',
    component: () => import('../components/MeusProjetos/MeusProjetos.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/meus-projetos/detalhe/:id',
    name: 'Meus Projetos - Detalhe',
    component: () =>
      import('../components/MeusProjetos/Detalhe/DetalheMeusProjetos.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edicao-projeto/:id',
    name: 'Edição de Projeto',
    component: () => import('../components/EdicaoProjeto/EdicaoProjeto.vue'),
    meta: {
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/cadastro-manual',
    name: 'Cadastro manual - turma',
    component: () =>
      import('../components/CadastroTurma/CadastroManual/CadastroManual.vue'),
    meta: {
      requiresAuth: true,
      name: 'Cadastro manual',
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/competencias/cadastrar',
    name: 'Cadastro de Competencias',
    component: () =>
      import('../views/competenciasCadastro/competenciasCadastro.vue'),
    meta: {
      requiresAuth: true,
      name: 'Competências',
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/competencias',
    name: 'Consulta de Competencias',
    component: () =>
      import('../views/competenciasConsulta/competenciasConsulta.vue'),
    meta: {
      name: 'Competências',
      requiresAuth: true,
    },
  },
  {
    path: '/cadastro-codigo',
    name: 'Cadastro de código de acesso - Turmas',
    component: () =>
      import('../components/CadastroTurma/CadastroCodigo/CadastroCodigo.vue'),
    meta: {
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/turmas',
    name: 'Listagem de Turmas',
    component: () => import('../components/Turmas/Turmas.vue'),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador', 'Aluno'],
    },
  },
  {
    path: '/turmas/detalhe/:id',
    name: 'Turma - Detalhe',
    component: () => import('../components/Turmas/Detalhe/DetalheTurmas.vue'),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador', 'Aluno'],
    },
  },
  {
    path: '/turmas/cadastrar',
    name: 'Cadastro de Turma',
    component: () =>
      import('../components/Turmas/cadastroTurma/cadastroTurma.vue'),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/turmas/editar/:id',
    name: 'Edição de Turma',
    component: () =>
      import('../components/Turmas/cadastroTurma/cadastroTurma.vue'),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
  {
    path: '/turmas/priorizacao/:id',
    name: 'Priorização de Tema',
    component: () =>
      import('../components/Turmas/PriorizacaoTema/priorizacaoTema.vue'),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Aluno', 'Administrador'],
    },
  },
  {
    path: '/turmas/priorizacao/detalhe/:id',
    name: 'Priorização de Tema - Detalhe',
    component: () =>
      import(
        '../components/Turmas/PriorizacaoTema/DetalhePriorizacao/detalhePriorizacao.vue'
      ),
    meta: {
      name: 'Turmas',
      requiresAuth: true,
      allowedUsers: ['Aluno', 'Administrador'],
    },
  },
  {
    path: '/peer',
    name: 'Template revisão de pares',
    component: () =>
      import(
        '../views/revisaoParesSolicitacao/revisaoParesTemplate/revisaoParesTemplate.vue'
      ),
    meta: {
      name: 'Competências',
      requiresAuth: true,
      allowedUsers: ['Professor', 'Administrador'],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: env.baseUrl,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { nextUrl: to.fullPath },
      });
    } else {
      const { type: userType } = store.getters.user;
      if (
        to.matched.some(record => record.meta.allowedUsers) &&
        !to.meta.allowedUsers.includes(userType) &&
        userType !== 'Administrador'
      ) {
        next({
          path: '/meus-projetos',
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
