<template>
  <div v-if="this.popUpActive">
    <div
      class="fixed w-screen h-screen bg-[#878787] mix-blend-multiply top-0 right-0 z-20"
    ></div>
    <div
      class="fixed hidden sm:flex justify-around top-6 left-[50%] w-[56rem] rounded-2xl bg-[#FFD154] z-40 -translate-x-1/2 max-w-[80%]"
    >
      <div class="w-[17.7rem] mt-14 ml-10 mb-14 font-[rubik]">
        <h2 class="font-bold text-2xl mb-4">
          {{ informations[type()].titulo }}
        </h2>
        <p class="text-lg leading-5">
          {{ informations[type()].data }}
        </p>
      </div>
      <div>
        <img
          class="w-[30rem]"
          src="../../assets/boasvindasPopUp/Maskgroup.png"
          alt=""
        />
      </div>
      <button class="w-fit h-fit p-3" @click="this.closePopUp">
        <img class="w-4 h-4" src="../../assets/contatoPopUp/x.png" alt="" />
      </button>
    </div>
    <div
      :class="
        'fixed flex flex-col sm:hidden justify-around top-2 left-[38%] w-[20rem] rounded-2xl bg-[#FFD154] z-40 -translate-x-1/2 sm:max-w-[80%] ' +
        (this.menuOpen ? 'ml-[9rem]' : 'ml-[2.8125rem]')
      "
    >
      <div class="flex">
        <div class="w-[17.7rem] mt-14 ml-10 mb-14 font-[rubik]">
          <h2 class="font-bold text-2xl mb-4">
            {{ informations[type()].titulo }}
          </h2>
          <p class="text-lg leading-5">
            {{ informations[type()].data }}
          </p>
        </div>
        <button class="w-fit h-fit p-3" @click="this.closePopUp">
          <img class="w-4 h-4" src="../../assets/contatoPopUp/x.png" alt="" />
        </button>
      </div>
      <div class="">
        <img
          class="w-[30rem]"
          src="../../assets/boasvindasPopUp/Maskgroup.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
import USER_CONST from '../../utils/enums/users.enum';

const informations = ref([
  {
    titulo: 'Olá, professor(a)!',
    data: 'Você pode acompanhar as submissões de propostas de projetos, as disciplinas e o andamento dos projetos.',
  },
  {
    titulo: 'Olá, estudante!',
    data: 'Você pode acompanhar os seus projetos e seus respectivos status, bem como suas disciplinas.',
  },
  {
    titulo: 'Olá, Stakeholder!',
    data: 'Você pode acompanhar as suas propostas de projetos submetidas a editais e seus respectivos status.',
  },
]);

export default defineComponent({
  name: 'WelcomePopUp',

  data() {
    return {
      user: this.$store.getters.user,
      menuOpen: false,
      popUpActive: false,
      type: () => {
        if (
          [USER_CONST.TYPES.PROFESSOR.KEY].some(type => type === this.user.type)
        )
          return 0;
        if (
          [USER_CONST.TYPES.STUDENT.KEY].some(type => type === this.user.type)
        )
          return 1;
        return 2;
      },
      informations,
    };
  },
  mounted() {
    this.popUpActive = !this.getHasLoggedBefore();
  },

  methods: {
    closePopUp() {
      this.popUpActive = false;
      this.setHasLoggedBefore();
    },
    getHasLoggedBefore() {
      const aux = localStorage.getItem('hasLoggedBefore');
      if (!aux) return false;
      const data = Object.values(JSON.parse(aux));
      return data.includes(this.user.userId.toString());
    },

    setHasLoggedBefore() {
      const aux = localStorage.getItem('hasLoggedBefore');
      if (!aux)
        localStorage.setItem(
          'hasLoggedBefore',
          JSON.stringify([this.user.userId.toString()]),
        );
      else
        localStorage.setItem(
          'hasLoggedBefore',
          JSON.stringify([...JSON.parse(aux), this.user.userId.toString()]),
        );
    },
  },
});
</script>
